import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { DespesasContext } from 'contexts/DespesasContext';
import { GridContext } from 'contexts/GridContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { toastWarning } from 'utils/toast';
import { useModal, useDialog } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import PrevisãoModal from './Modals/PrevisãoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Despesas = () => {
  const rotina = 'Despesas';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { postDocumentos } = useContext(DocumentosContext);
  const { exportPdf, exportXls, deleteGrid } = useContext(GridContext);
  const { despesas, getDespesas, getLoading, exportXML } =
    useContext(DespesasContext);
  const { control, getValues, reset } = useForm({
    defaultValues: despesas?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getDespesas({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar Despesa',
      icon: 'add',
      action: () => navigate('/app/Despesas/Gerar'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: despesas?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: despesas?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={despesas?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Registrar Notas',
      icon: 'send',
      action: (selected) => {
        const notas = selected?.filter((f) =>
          Boolean(f?.status !== 'CONCLUIDO')
        );
        if (!Boolean(notas?.length)) {
          return toastWarning('Selecione apenas documentos não concluídos');
        } else {
          postDocumentos({
            data: selected?.map((s) => ({
              Documento: { id: s?.id, conferido: 'SIM' },
            })),
            cb: loadGrid,
          });
        }
      },
    },
  ];
  const rowOptions = [
    {
      name: 'Aprovação necessária',
      icon: 'priority_high',
      color: 'secondary.main',
      show: ({ row }) => row?.aprovacao === 'PENDENTE',
    },
    {
      name: 'Aprovado',
      icon: 'check',
      color: 'primary.main',
      show: ({ row }) => row?.aprovacao === 'SIM',
    },
    {
      name: ' Reprovado',
      icon: 'close',
      color: 'secondary.main',
      show: ({ row }) => row?.aprovacao === 'NAO',
    },
    {
      name: 'Baixar Previsão',
      icon: 'price_check',
      show: ({ row }) => Boolean(row?.previsoes),
      action: ({ row }) =>
        openDialog(
          <PrevisãoModal
            item={row}
            onSubmit={(params) =>
              deleteGrid({
                params,
                cb: () => {
                  loadGrid();
                  closeDialog();
                },
              })
            }
          />,
          'Baixar Previsão'
        ),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} colunas={despesas?.colunas} />
      <Card>
        <Grid
          grid={despesas}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={despesas} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Despesas;
