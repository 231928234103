import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { PendênciasContext } from 'contexts/PendênciasContext';
import { RecebimentosPendentesContext } from 'contexts/RecebimentosPendentesContext';
import { useModal, useDialog } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import BoletoModal from 'components/Modals/BoletoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import AlteraçãoModal from '../Modals/AlteraçãoModal';
import Filter from './filter';
import mock from './mock';

const RecebimentosPendentes = () => {
  const rotina = 'RecebimentosPendentes';
  const titulo = 'Recebimentos Pendentes';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { postBoletos, deleteBoletos, getBoletosBaixados } =
    useContext(PendênciasContext);
  const { recebimentosPendentes, getRecebimentosPendentes, getLoading } =
    useContext(RecebimentosPendentesContext);
  const { control, getValues, reset } = useForm({
    defaultValues: recebimentosPendentes?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getRecebimentosPendentes({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: recebimentosPendentes?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: recebimentosPendentes?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={recebimentosPendentes?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Baixar Pendências',
      icon: 'price_check',
      action: (recebimentos) => {
        if (!Boolean(recebimentos?.length)) {
          return toastWarning('Selecione ao menos um item');
        }

        if (new Set(recebimentos?.map((r) => r?.filial_id)).size > 1) {
          return toastWarning('Selecione apenas pendências da mesma filial');
        }

        navigate('/app/Pendencias/Baixa/Recebimentos', {
          state: { recebimentos },
        });
      },
    },
    {
      name: 'Alterar Dados',
      icon: 'edit',
      action: (financeiros) => {
        if (!Boolean(financeiros?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        openDialog(
          <AlteraçãoModal
            financeiros={financeiros}
            callback={loadGrid}
            modulo="VENDAS"
          />,
          'Alterar Dados'
        );
      },
    },
    {
      name: 'Registrar Boletos',
      icon: 'attach_money',
      action: (financeiros) => {
        if (!Boolean(financeiros?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        const data = financeiros?.map((f) => f?.id);
        postBoletos({
          data,
          cb: loadGrid,
        });
      },
    },
    {
      name: 'Desregistrar Boletos',
      icon: 'money_off',
      action: (financeiros) => {
        if (!Boolean(financeiros?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        const data = financeiros?.map((f) => f?.id);
        deleteBoletos({
          data,
          cb: loadGrid,
        });
      },
    },
    {
      name: 'Atualizar Boletos',
      icon: 'refresh',
      action: () => getBoletosBaixados({ cb: loadGrid }),
    },
    {
      name: 'Boletos',
      icon: 'print',
      action: (financeiros) => {
        if (!Boolean(financeiros?.length)) {
          return toastWarning('Selecione ao menos um item');
        }

        const notBoleto = financeiros?.some((s) => !s?.boleto);

        if (notBoleto) {
          return toastWarning('Selecione somente pendências com boleto');
        }

        const notValidCliente = financeiros?.some(
          (s) => s?.entidade_id !== financeiros[0]?.entidade_id
        );

        if (notValidCliente) {
          return toastWarning('Selecione somente pendências do mesmo cliente');
        }

        getURLRelatorio({
          data: {
            codigo: 'BOL0000001',
            financeiros: JSON.stringify(financeiros?.map((a) => a?.id)),
          },
          cb: loadGrid,
        });
      },
    },
  ];

  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.documento_id}`),
    },
    {
      name: ' Boleto registrado',
      icon: 'task',
      color: 'primary.main',
      show: ({ row }) => Boolean(row?.remessa),
    },
    {
      name: ' Consultar Boleto',
      icon: 'plagiarism',
      show: ({ row }) => Boolean(row?.boleto),
      action: ({ row }) => openModal(<BoletoModal id={row?.id} />),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={recebimentosPendentes?.colunas}
      />
      <Card>
        <Grid
          grid={recebimentosPendentes}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          totalizer="valor_pendente"
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter
              grid={recebimentosPendentes}
              control={control}
              drops={drops}
            />
          }
        />
      </Card>
    </Container>
  );
};

export default RecebimentosPendentes;
