import React, { useContext, useEffect } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit, QueryStats, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { findOnArray, summarizer, getDatePagto } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import api from 'services/api';
import ItemModal from './Modals/ItemModal';
import PagamentoModal from './Modals/PagamentoModal';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const { type } = useParams();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const defaultValues = {
    filial_id: user?.filial_id,
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    aporte: false,
    cadastro_id: null,
    observacao: '',
    //ITENS
    descricao: '',
    quantidade: '',
    preco: '',
    planoconta_id: null,
    ccusto_id: null,
    aplicacao_id: null,
    frota_id: null,
    itens: [],
    //PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    fixar: false,
    pagamento: [],
  };
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  const dropForma = drops?.FormaDePagamento?.filter(
    (f) =>
      f?.modalidade !== '05-CRÉDITO LOJA' &&
      (f?.modulo === (type === 'Cliente' ? 'VENDAS' : 'COMPRAS') ||
        f?.modulo === 'GERAL')
  );

  const dropCondicao = drops?.CondicaoPagamento?.filter(
    (c) =>
      c?.modulo === (type === 'Cliente' ? 'VENDAS' : 'COMPRAS') ||
      c?.modulo === 'GERAL'
  );

  useEffect(() => {
    handleSubmit(onRecalc)();
  }, [watch('itens')]);

  const onAddItem = (values) => {
    const subtotal = (values?.quantidade || 0) * (values?.preco || 0);
    setValue('itens', [
      ...values?.itens,
      {
        subtotal,
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        preco: values?.preco,
        planoconta_id: values?.planoconta_id,
        ccusto_id: values?.ccusto_id,
        aplicacao_id: values?.aplicacao_id,
        frota_id: values?.frota_id,
      },
    ]);
    resetField('descricao');
    resetField('quantidade');
    resetField('preco');
    resetField('planoconta_id');
    resetField('ccusto_id');
    resetField('aplicacao_id');
    resetField('frota_id');
  };

  const onEditItem = ({ values, index }) => {
    const itens = watch('itens');
    const item = itens[index];
    if (values?.quantidade > item?.quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${item?.quantidade_max})`
      );
    }
    if (Boolean(item?.preco_max) && values?.preco > item?.preco_max) {
      return toastWarning(
        `Preço limite excedido. (Limite: ${item?.preco_max})`
      );
    }

    const subtotal = (values?.quantidade || 0) * (values?.preco || 0);
    setValue(
      'itens',
      itens?.map((itm, i) =>
        index === i ? { ...itm, ...values, subtotal } : itm
      )
    );
    closeDialog();
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
  };

  const onChangePagto = (values) => {
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    if (Boolean(forma_pagto_id) && Boolean(condicao_pagamento_id)) {
      const cond_pagto = findOnArray(condicao_pagamento_id, dropCondicao);
      const valor_total = summarizer(values?.itens, 'subtotal');
      if (cond_pagto && valor_total > 0) {
        const pagamento = [];
        let valor_lancado = 0;
        for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
          const valor = parseFloat(
            (valor_total / cond_pagto?.nvezes).toFixed(2)
          );
          pagamento.push({
            parcela,
            dtvenc: getDatePagto({
              ...cond_pagto,
              parcela,
              fixar,
              start_date: values?.dtemissao,
            }),
            valor:
              parcela === cond_pagto?.nvezes
                ? valor_total - valor_lancado
                : valor,
            forma_pagto_id,
          });
          valor_lancado += valor;
        }
        setValue('pagamento', pagamento);
      } else {
        setValue('pagamento', []);
      }
    }
  };

  const onEditPagto = ({ values, index }) => {
    const pagamento = watch('pagamento');
    setValue(
      'pagamento',
      pagamento?.map((p, i) =>
        index === i
          ? { ...p, ...values, parcela: i + 1 }
          : { ...p, parcela: i + 1 }
      )
    );
    closeDialog();
  };

  const onDeletePagto = (index) => {
    setValue(
      'pagamento',
      watch('pagamento')?.filter((_, i) => i !== index)
    );
  };

  const onRecalc = (values) => {
    const valor_total = summarizer(values?.itens, 'subtotal');
    const parcelas = values?.pagamento?.length;
    if (Boolean(parcelas) && valor_total > 0) {
      let valor_lancado = 0;
      const pagamento = values?.pagamento.map((p, i) => {
        let valor =
          parcelas === i + 1
            ? valor_total - valor_lancado
            : parseFloat((valor_total / parcelas).toFixed(2));
        valor_lancado += valor;
        return { ...p, valor };
      });
      setValue('pagamento', pagamento);
    } else {
      setValue('pagamento', []);
    }
  };

  const onChangeEntidade = async (v) => {
    if (v) {
      const params = {
        entidade_id: v?.value,
        tipo: 'CLIENTE',
      };
      const { data } = await api.get(`/Cadastros/Entidade/Complemento`, {
        params,
      });
      if (data?.condicao_pagamento_id) {
        setValue('condicao_pagamento_id', data?.condicao_pagamento_id);
      }
      if (data?.forma_pagto_id) {
        setValue('forma_pagto_id', data?.forma_pagto_id);
      }
    }
  };

  const onSubmit = (values) => {
    const especie_id =
      type === 'Cliente' ? 21 : type === 'Fornecedor' ? 22 : null;
    const natureza_operacao_id =
      type === 'Cliente'
        ? values?.aporte
          ? 75
          : 54
        : type === 'Fornecedor'
        ? 46
        : null;
    let data = {
      Documento: {
        especie_id,
        natureza_operacao_id,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: values?.dtemissao,
        observacao: values?.observacao,
        conferido: 'SIM',
      },
      Itens: values?.itens?.map((i, index) => ({ sequencia: index + 1, ...i })),
      Financeiros: values?.pagamento,
    };

    if (type === 'Cliente') {
      data = {
        ...data,
        Saida: {
          condicao_pagamento_id: values?.condicao_pagamento_id,
          dtsaida: moment().format('YYYY-MM-DD'),
        },
      };
    }
    if (type === 'Fornecedor') {
      data = {
        ...data,
        Entrada: {
          dtentrada: moment().format('YYYY-MM-DD'),
        },
      };
    }

    postDocumento({ data, cb: () => navigate(-1) });
  };

  return (
    <Container>
      <Header titulo="Gerar Haver" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={type === 'Cliente' ? 6 : 9}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          {type === 'Cliente' && (
            <Grid item xs={12} sm={3} textAlign="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={watch('aporte')}
                    onChange={() => setValue('aporte', !watch('aporte'))}
                  />
                }
                label="Aporte no Caixa"
              />
            </Grid>
          )}
          <Grid item xs={10}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
              onValueChange={onChangeEntidade}
              refresh="Entidade"
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <Visibility fontSize="small" />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <QueryStats fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input name="descricao" control={control} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask name="preco" control={control} label="Preço" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="planoconta_id"
                    control={control}
                    label="Plano de Conta"
                    options={drops?.Planoconta?.filter(
                      (p) => p?.operacional === 'SIM'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="ccusto_id"
                    control={control}
                    label="Centro de Custo"
                    options={drops?.Ccusto}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="aplicacao_id"
                    control={control}
                    label="Aplicação"
                    options={drops?.Aplicacao}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="frota_id"
                    control={control}
                    label="Frota"
                    options={drops?.Frota}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Quantidade</TableCell>
                          <TableCell>Preço Unitário</TableCell>
                          <TableCell>Sub Total</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('itens')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.descricao}</TableCell>
                            <TableCell>{item?.quantidade}</TableCell>
                            <TableCell>
                              {item?.preco?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item?.subtotal?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <ItemModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditItem({ values, index })
                                      }
                                    />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('itens'), 'subtotal')?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Pagamento" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="forma_pagto_id"
                    control={control}
                    label="Forma de Pagamento"
                    options={dropForma}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="condicao_pagamento_id"
                    control={control}
                    label="Condição de Pagamento"
                    options={dropCondicao}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox name="fixar" control={control} label="Fixar Dia" />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onChangePagto)}
                  >
                    CALCULAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcela</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Forma de Pagamento</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('pagamento')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.parcela}</TableCell>
                            <TableCell>
                              {Boolean(item?.dtvenc) &&
                                moment(item?.dtvenc).isValid() &&
                                moment(item?.dtvenc).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>
                              {item?.valor?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {findOnArray(
                                item?.forma_pagto_id,
                                dropForma,
                                'label'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <PagamentoModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditPagto({ values, index })
                                      }
                                    />,
                                    'Editar Parcela'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeletePagto(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Gerar;
